//线上 电子章申请签约
<template>
  <div class="applyOnlineSigning"
  v-loading="loading"
   element-loading-text="正在签章中···"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 0.3)"
  >
    <!-- 返回 -->
    <backtitle lable="在线签约" />
    <!-- 头部汇总 -->
    <div class="signBackground">
      <sign-header :summaryData="summaryData"></sign-header>
      <div class="contractAmount" v-if="summaryData.status !=='2'">
        合同总文件数 <span class="contractTotal">{{ contractCount }}</span>
      </div>
      <!-- 平台文件 -->
      <sign-title
        :showCheckbox="false"
        :checked.sync="platformChecked"
        label="平台盖章文件"
        :amount="contractCount"
      >
      </sign-title>
      <base-table
        class="main-page-table"
        :columns="platformColumns"
        :showPage="false"
        :tableAttrs="{
          stripe: false,
          data:platformData,
          border:true,
           spanMethod: objectSpanMethod,
        }"
        ref="tableData"
         :is-caculate-height="false"
      >
        <template slot="index" slot-scope="scope">{{
          scope.$index + 1
        }}</template>
        <template slot="action" slot-scope="scope">
          <icon-button
            @click="showPreview(scope.row)"
            content="预览"
            icon="iconfont iconyulan"
          />
        </template>
      </base-table>
      <!-- 供应商文件 --> <!--电子章二期 暂时不做-->
      <!-- <sign-title
        :showCheckbox="true"
        :checked.sync="supplierChecked"
        label="供应商补盖文件"
        :amount="supplieraAmount"
      ></sign-title>
      <div class="warning"><i class="el-icon-warning"></i>请确认供应商以下文件是否可由稳诚一方协助盖章:</div>
      <base-table
        class="main-page-table"
        :columns="supplierColumns"
        :showPage="false"
        :tableAttrs="{
          stripe: true,
        }"
        :dataSource.sync="supplierData"
        :api="api"
        :getApi="'getPage'"
        ref="tableData"
        :loadCount="loadCount"
        :webPage="false"
        :is-caculate-height="false"
      >
        <template slot="action" slot-scope="scope">
          <icon-button
            @click="showPreview(scope.row)"
            content="预览"
            icon="iconfont iconyulan"
          />
        </template>
      </base-table> -->
    </div>
    <!-- 底部提交按钮 -->
    <div class="footer" v-if="hiddenSignBtn">
      <base-button
        label="自动发起电子签章"
        @click="startSignature"
      ></base-button>
    </div>
    <!-- 列表查看预览文件 -->
    <!-- <preview-dialog
      :previsible.sync="previsible"
      :fileId="fileId"
      :dialogType="'list'"
      :fileType="fileType"
    ></preview-dialog> -->
    <pre-view :fileId="fileId" :isOpen='true' :fileType="fileType" :count="fileCount" />
    <!-- 文件信息弹窗 -->
      <contract-amount
      :filesInfoVisible.sync="filesInfoVisible"
      ></contract-amount>

  </div>
</template>
<script>
import PreView from '@/components/pre-view/pre-view.vue'
import backtitle from '@/pages/business/components/backtitle.vue'
import BaseTable from '@/components/common/table/base-table/base-table'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import SignHeader from '../components/sign-header.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import SignTitle from '../components/sign-title.vue'

import contractAmount from './components/contract-amount.vue'
import { getDictLists } from '@/filters/fromDict'
import { signPosition, signApi } from '@/api/businessApi'
import Storage from '@/utils/storage'
import { platformTable, supplierTable } from './utils/online-config'
import { filterData } from './utils/utils'
export default {
  name: 'applyOnlineSigning',
  components: {
    BaseTable,
    BaseButton,
    backtitle,
    SignTitle,
    IconButton,

    SignHeader,
    contractAmount,
    PreView
  },
  data () {
    return {
      fileCount: 0,
      loading: false, // 手动遮罩
      contractCount: '',
      summaryData: {},
      count: 0,
      platformData: [],
      supplierData: [],
      loadCount: 0,
      previsible: false,
      fileId: '',
      fileType: '',
      platformChecked: false,
      platformAmount: '',
      supplieraAmount: '',
      supplierChecked: '',
      filesInfoVisible: false, // 文件信息弹窗
      hiddenSignBtn: true // 显示隐藏签章按钮
    }
  },
  mounted () {
    this.summaryData = Storage.getSession('onlineSginData')
    this.contractCount = this.summaryData.contractCount.toString()
    this.getSignDetail()
    this.firstSignResult()
  },
  computed: {
    platformColumns () {
      return platformTable(this)
    },
    supplierColumns () {
      return supplierTable(this)
    },

    // 盖章类型
    signerType () {
      return getDictLists('SEAL_TYPE')
    }
  },
  methods: {
    // 获取文件列表
    getSignDetail () {
      signApi.getSignDetail({ taskId: this.summaryData.keyId }).then((res) => {
        if (res.data) {
          this.platformData = filterData(res.data, 'docName', 'docNameRowSpan')
          this.platformData = filterData(res.data, 'fileName', 'fileNameRowSpan')
        }
      })
    },
    // 表格合并规则
    objectSpanMethod ({ row, column }) {
      // if (this.summaryData.suppliersSealType === '0') {
      //   // 鲜章无合并
      //   return []
      // }
      if (column.property === 'fileName') {
        const _row = row.fileNameRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (column.property === 'docName') {
        const _row = row.docNameRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    // 发起签章
    startSignature () {
      signPosition.sendAutoSign({ taskId: this.summaryData.keyId }).then(res => {
        if (res.data) {
          this.loading = true
          const timer = setInterval(() => {
            this.querySignResult(timer)
          }, 1000)
        }
      })
    },
    firstSignResult () {
      signApi.getTaskInfo({ taskId: this.summaryData.keyId }).then(res => {
        if (res.data.status === '2') {
          this.hiddenSignBtn = false
        }
      })
    },
    // 查询签章结果
    querySignResult (timer) {
      signApi.getTaskInfo({ taskId: this.summaryData.keyId }).then(res => {
        if (res.data.status === '2') {
          clearInterval(timer)
          this.loading = false
          this.hiddenSignBtn = false
          this.getSignDetail()
          this.success('签约成功')
        } else if (res.data.status === '3') {
          clearInterval(timer)
          this.loading = false
          this.error('签约失败')
          this.getSignDetail()
        }
      }).catch(err => {
        console.log(err)
        clearInterval(timer)
      })
    },
    // 展示预览
    showPreview (data) {
      console.log(data, 222)
      this.fileId = data.currentFileId ? data.currentFileId : ''
      const fileSuffix = data.fileName ? data.fileName.split('.') : []
      this.fileType = fileSuffix[fileSuffix.length - 1]
      // this.previsible = true
      this.fileCount++
    }
    // 合同文件总数
    // showDetails () {
    //   // this.filesInfoVisible = true
    // }
  }
}
</script>
<style lang="scss" src='./index.scss' scoped />
