
// 平台表格
export const platformTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '文件模板名',
      prop: 'docName',
      'show-overflow-tooltip': true,
      align: 'left'
    },
    {
      label: '文件名称',
      prop: 'fileName',
      'show-overflow-tooltip': true,
      align: 'left'
    },
    {
      label: '盖章类别',
      prop: 'name'
    },
    {
      label: '盖章类型',
      prop: 'signerType',
      formatter: row => {
        const [obj] = content.signerType.filter(item => item.dictId === row.signerType)
        return obj ? obj.dictName : ''
      }
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 供应商表格  电子章二期， 暂时不上
export const supplierTable = () => {
  return [
    // {
    //   label: '序号',
    //   prop: 'index',
    //   width: '80px'
    // },
    // {
    //   label: '业务编号',
    //   prop: 'businessNo'
    // },
    // {
    //   label: '供应商',
    //   prop: 'businessNo',
    //   align: 'left',
    //   'show-overflow-tooltip': true
    // },
    // {
    //   label: '申请金额(元)',
    //   prop: '',
    //   'show-overflow-tooltip': true
    // },
    // {
    //   label: '盖章文件数',
    //   prop: '',
    //   'show-overflow-tooltip': true
    // },
    // {
    //   label: '文件名称',
    //   prop: '',
    //   'show-overflow-tooltip': true,
    //   align: 'left'
    // },
    // {
    //   label: '盖章方式',
    //   prop: ''
    // },
    // {
    //   label: '签约状态',
    //   prop: ''
    // },
    // {
    //   label: '操作',
    //   prop: 'action'
    // }
  ]
}
