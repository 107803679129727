// 文件信息弹窗    暂时没有数据
<template>
  <base-dialog
    :visible.sync="currentVisible"
    :showFooter="false"
    title="文件信息"
  >
    <div>
      <title-center name="查看"></title-center>
      <base-table
       :columns="columns"
          :showPage="false"
          :tableAttrs="{
            stripe: true,
            'max-height':'200'
          }"
          :dataSource.sync="infoData"
          :is-caculate-height="false"
      >
      </base-table>
    </div>
    <template slot="footer">
      <base-button
        label="关 闭"
        type="default"
        @click="currentVisible = false"
      ></base-button>
    </template>
  </base-dialog>
</template>
<script>
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { contractFileInfo } from '../utils/details-config'
export default {
  components: { baseDialog, BaseButton, BaseTable },
  name: 'detailDialog',
  props: {
    // formInfoconfig: Array,
    // detailInfo: Object,
    filesInfoVisible: Boolean
  },
  data () {
    return {
      count: 0,
      infoData: []
    }
  },
  mounted () {
    // this.summaryData = Storage.getSession('onlineSginData')
  },
  computed: {
    currentVisible: {
      get () {
        return this.filesInfoVisible
      },
      set (val) {
        this.$emit('update:filesInfoVisible', val)
      }
    },
    columns () {
      return contractFileInfo(this)
    }
  },
  methods: {

  }
}
</script>
